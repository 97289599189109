/**
 * Created by Gayratjon Rayimjonov on 10/24/2017.
 */

(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsPeriodicalController', StatsPeriodicalController);

    StatsPeriodicalController.$inject = ['$http', '$scope', '$state', 'DateUtils', 'DataUtils', 'StatsPeriodical'];

    function StatsPeriodicalController($http, $scope, $state, DateUtils, DataUtils, StatsPeriodical) {
        var vm = this;
        vm.search = search;
        vm.excel = excel;
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;
        vm.searchQuery = {};
        vm.stats = {
            totalTransactionsAmount : 0,
            depositAmount: 0,
            largestTransactionsAmount: 0,
            largestDepositAmount: 0,
            newUsers: 0,
            activeUsers: 0,
            failureTransactionCount: 0,
            plusBonus: 0,
            minusBonus: 0
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function search() {
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.toDate);
            StatsPeriodical.query(vm.searchQuery, onSuccess);

            function onSuccess(data) {
                vm.stats = data;
            }
        };

        function excel() {
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.toDate);
            var data = "client=" + vm.searchQuery.client + "&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/stats-periodical-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'periodical-statistics-' + DateUtils.currentTimestamp() + '-' + vm.searchQuery.client + '.xlsx');
            }).error(function () {
            });
        }
    }
})();

