(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('QiwiPaymentDetailController', QiwiPaymentDetailController);

    QiwiPaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'QiwiPayment', 'QiwiAtol', 'Customer'];

    function QiwiPaymentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, QiwiPayment, QiwiAtol, Customer) {
        var vm = this;

        vm.qiwiPayment = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:qiwiPaymentUpdate', function(event, result) {
            vm.qiwiPayment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
