/**
 * Created by Gayratjon Rayimjonov on 9/22/2017.
 */
(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileStatusDialogController', ProfileStatusDialogController);

    ProfileStatusDialogController.$inject = ['$scope', '$timeout', '$uibModalInstance', 'entity', 'CustomerStatusLog'];

    function ProfileStatusDialogController($scope, $timeout, $uibModalInstance, entity, CustomerStatusLog) {
        var vm = this;

        vm.profile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.getStatusOptions = getStatusOptions;
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.customerStatusLogs = {
                profileId:vm.profile.id,
                changeReason: vm.reason,
                status: vm.customerStatus
            };

            CustomerStatusLog.save(vm.customerStatusLogs, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.profile.customerFullName = result.fullName;
            vm.profile.customerStatus = result.status;
            vm.profile.balance = result.balance;
            vm.profile.totalMileage = result.totalMileage;
            $scope.$emit('bozorakaAdminApp:profileUpdate', vm.profile);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getStatusOptions() {
            if (vm.profile.customerStatus == 'ACTIVE') {
                return ['DEACTIVATED', 'BANNED'];
            } else if (vm.profile.customerStatus == 'DEACTIVATED') {
                return ['ACTIVE', 'BANNED'];
            } else if (vm.profile.customerStatus == 'BANNED') {
                return ['REACTIVATED'];
            } else {
                return [];
            }
        }
    }
})();
