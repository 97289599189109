(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$http', '$scope', '$state', 'Profile', 'ProfileSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'DataUtils'];

    function ProfileController($http, $scope, $state, Profile, ProfileSearch, ParseLinks, AlertService, paginationConstants, pagingParams, DataUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.excel = excel;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;
        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            status: pagingParams.status,
            client: pagingParams.client
        };

        initDatePicker();

        if (!pagingParams.firstRun) {
            loadAll();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll() {
            Profile.query({
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                customerStatus: vm.searchQuery.status,
                client: vm.searchQuery.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.profiles = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                status: vm.searchQuery.status,
                client: vm.searchQuery.client,
                firstRun: false
            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });

            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                fromDate: null,
                toDate: null,
                status: "UNKNOWN",
                client: ""
            };
            vm.transition();
        }

        function excel() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            var data = "status=" + vm.searchQuery.status + "&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/profile-stats-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'users-profile.xlsx');
            }).error(function () {
            });

        }
    }
})();
