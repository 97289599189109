(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PaymentMethodFieldOptionDetailController', PaymentMethodFieldOptionDetailController);

    PaymentMethodFieldOptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PaymentMethodFieldOption'];

    function PaymentMethodFieldOptionDetailController($scope, $rootScope, $stateParams, previousState, entity, PaymentMethodFieldOption) {
        var vm = this;

        vm.paymentMethodFieldOption = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:paymentMethodFieldOptionUpdate', function(event, result) {
            vm.paymentMethodFieldOption = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
