(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderSegmentController', ProviderSegmentController);

    ProviderSegmentController.$inject = ['$state', 'ProviderSegment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ProviderSegmentController($state, ProviderSegment, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = {
            name: pagingParams.name,
            providerName: pagingParams.providerName,
            kashServiceId: pagingParams.kashServiceId,
            accountFieldId: pagingParams.accountFieldId,
            amountCharge: pagingParams.amountCharge,
            amountField: pagingParams.amountField,
            type: pagingParams.type,
            mode: pagingParams.mode,
            status: pagingParams.status
        };
        if (!pagingParams.firstRun) {
            loadAll();
        }

        function loadAll() {
            ProviderSegment.query({
                name: pagingParams.name,
                providerName: pagingParams.providerName,
                kashServiceId: pagingParams.kashServiceId,
                accountFieldId: pagingParams.accountFieldId,
                amountCharge: pagingParams.amountCharge,
                amountField: pagingParams.amountField,
                type: pagingParams.type,
                mode: pagingParams.mode,
                status: pagingParams.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.providerSegments = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                name: vm.searchQuery.name,
                providerName: vm.searchQuery.providerName,
                kashServiceId: vm.searchQuery.kashServiceId,
                accountFieldId: vm.searchQuery.accountFieldId,
                amountCharge: vm.searchQuery.amountCharge,
                amountField: vm.searchQuery.amountField,
                type: vm.searchQuery.type,
                mode: vm.searchQuery.mode,
                status: vm.searchQuery.status,
                firstRun: false
            });
        }

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.searchQuery = {
                name: null,
                providerName: null,
                kashServiceId: null,
                accountFieldId: null,
                amountCharge: null,
                amountField: null,
                type: null,
                mode: null,
                status: null
            };
            vm.transition();
        }
    }
})();
