(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PaymentMethodTypeDetailController', PaymentMethodTypeDetailController);

    PaymentMethodTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PaymentMethodType'];

    function PaymentMethodTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, PaymentMethodType) {
        var vm = this;

        vm.paymentMethodType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:paymentMethodTypeUpdate', function(event, result) {
            vm.paymentMethodType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
