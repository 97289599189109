(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileDetailController', ProfileDetailController);

    ProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Profile', 'Customer', 'DataUtils', 'CustomerStatusLog'];

    function ProfileDetailController($scope, $rootScope, $stateParams, previousState, entity, Profile, Customer, DataUtils, CustomerStatusLog) {
        var vm = this;

        vm.profile = entity;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.getCustomerStatusLogsByCustomerId = getCustomerStatusLogsByCustomerId;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;
        vm.newPassword = null;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:profileUpdate', function (event, result) {
            vm.profile = result;
        });

        $scope.$on('$destroy', unsubscribe);
        getCustomerStatusLogsByCustomerId();

        function save() {
            vm.customer = {
                id: vm.profile.customerId,
                fullName: vm.profile.customerFullName,
                status: vm.profile.customerStatus,
            };
            vm.isSaving = true;
            if (vm.profile.customerId !== null) {
                Customer.update(vm.customer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.profile.customerFullName = result.fullName;
            vm.profile.customerStatus = result.status;
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getCustomerStatusLogsByCustomerId() {
            CustomerStatusLog.query({customerId: vm.profile.customerId}, function (data) {
                vm.customerStatusLogs = data;
            });
        }
    }
})();
