(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('transactionMode', transactionMode);

    var modes = {
        MANUAL: {
            name: 'M',
            style: 'btn yellow-mint btn-xs'
        },
        AUTO: {
            name: 'A',
            style: 'btn purple-sharp btn-xs'
        },
        DISABLED: {
            name: 'D',
            style: 'btn grey-salsa btn-xs'
        },
        IDLE: {
            name: 'I',
            style: 'btn grey-salsa btn-xs'
        }
    };

    function transactionMode() {
        var directive = {
            restrict: 'EA',
            controller: TransactionModeController,
            controllerAs: 'vm',
            bindToController: true,
            template: '<button type="button" class="{{vm.style}}" style="pointer-events: none">' +
            '{{vm.name | translate}}' +
            '</button>',
            scope: {
                mode: '<'
            }
        };

        return directive;
    }

    function TransactionModeController() {
        var vm = this;

        setMode();

        function setMode() {
            var mode = modes[vm.mode];
            vm.name = mode.name;
            vm.style = mode.style;
        }
    }
})();
