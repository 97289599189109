(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('app-version-codes', {
                parent: 'entity',
                url: '/app-version-codes',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'global.menu.configuration.version.codes'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/app-version-codes.html',
                        controller: 'ConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
