(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'DataUtils', 'YandexToken', 'HermesBalance'];

    function HomeController($scope, Principal, $state, DataUtils, YandexToken, HermesBalance) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.getBankNameByIndex = DataUtils.getBankNameByIndex;
        vm.register = register;

        if (Principal.hasAnyAuthority(['ROLE_MERCHANT'])) {
            $state.go('merchant');
        }

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();
        getHermesBalance();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }

        function getHermesBalance() {
            HermesBalance.get(function (data) {
                console.log(data);
                angular.forEach(data.Balances, function (v, k) {
                    if (v.Currency === "KZT") {
                       vm.hermesBalance = v.Balance;
                       vm.hermesOverBalance = v.Overbalance;
                    }
                });
            });
        }
    }
})();
