(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('RobokassaAtolDeleteController',RobokassaAtolDeleteController);

    RobokassaAtolDeleteController.$inject = ['$uibModalInstance', 'entity', 'RobokassaAtol'];

    function RobokassaAtolDeleteController($uibModalInstance, entity, RobokassaAtol) {
        var vm = this;

        vm.robokassaAtol = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RobokassaAtol.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
