(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('transactionService', transactionService);

    var services = {
        SERVICE_CHANGE_BALANCE: {
            name: 'Balance',
            style: 'btn grey-cascade btn-xs'
        },
        SERVICE_PAYMENT: {
            name: 'Payment',
            style: 'btn green-meadow btn-xs'
        },
        SERVICE_GIFT: {
            name: 'Gift',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_UNKNOWN: {
            name: '',
            style: 'display-none'
        }
    };

    function transactionService() {

        var template = '<button type="button" class="{{vm.style}}" style="pointer-events: none">' +
            '{{vm.name}}' +
            '</button>';

        var directive = {
            restrict: 'EA',
            template: template,
            controller: TransactionServiceController,
            controllerAs: 'vm',
            scope: {
                type: '<'
            },
            bindToController: true
        };

        return directive;
    }

    function TransactionServiceController() {
        var vm = this;

        setService();

        function setService() {
            if (vm.type === undefined) {
                vm.type = 'SERVICE_UNKNOWN';
            }
            var service = services[vm.type];
            vm.name = service.name;
            vm.style = service.style;
        }
    }
})();
