(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('UnitellerPaymentController', UnitellerPaymentController);

    UnitellerPaymentController.$inject = ['$state', 'UnitellerPayment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone', 'UnitellerPaymentTotalAmount'];

    function UnitellerPaymentController($state, UnitellerPayment, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone, UnitellerPaymentTotalAmount) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.openProfile = openProfile;
        vm.paymentTypes = {
            0: "BankCard",
            18: "Qiwi",
            29: "WebMoney"
        };
        vm.currentSearch = pagingParams.search;
        vm.searchQuery = {
            field: pagingParams.field,
            client: pagingParams.client,
            amount: pagingParams.amount,
            valueString1: pagingParams.valueString1,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();
        loadAll();
        getTotalAmount();

        function loadAll() {
            UnitellerPayment.query({
                field: pagingParams.field,
                client: pagingParams.client,
                valueString1: pagingParams.valueString1,
                amount: pagingParams.amount,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.unitellerPayments = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                client: vm.searchQuery.client,
                valueString1: vm.searchQuery.valueString1,
                amount: vm.searchQuery.amount,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            });
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                client: null,
                amount: null,
                valueString1: null,
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function getTotalAmount() {
            UnitellerPaymentTotalAmount.get({
                field: pagingParams.field,
                client: pagingParams.client,
                amount: pagingParams.amount,
                valueString1: vm.searchQuery.valueString1,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate
            }, function (data) {
                vm.totalAmount = data.totalAmount;
            })
        }
    }
})();
