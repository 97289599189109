(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PaymentMethodFieldOptionDeleteController',PaymentMethodFieldOptionDeleteController);

    PaymentMethodFieldOptionDeleteController.$inject = ['$uibModalInstance', 'entity', 'PaymentMethodFieldOption'];

    function PaymentMethodFieldOptionDeleteController($uibModalInstance, entity, PaymentMethodFieldOption) {
        var vm = this;

        vm.paymentMethodFieldOption = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PaymentMethodFieldOption.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
