(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('gift-order', {
                parent: 'entity',
                url: '/gift-order?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.giftOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gift-order/gift-orders.html',
                        controller: 'GiftOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('giftOrder');
                        $translatePartialLoader.addPart('giftEventType');
                        $translatePartialLoader.addPart('giftOrderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('gift-order-detail', {
                parent: 'gift-order',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.giftOrder.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gift-order/gift-order-detail.html',
                        controller: 'GiftOrderDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('giftOrder');
                        $translatePartialLoader.addPart('giftEventType');
                        $translatePartialLoader.addPart('giftOrderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'GiftOrder', function ($stateParams, GiftOrder) {
                        return GiftOrder.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'gift-order',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('gift-order-detail.edit', {
                parent: 'gift-order-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift-order/gift-order-dialog.html',
                        controller: 'GiftOrderDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['GiftOrder', function (GiftOrder) {
                                return GiftOrder.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('gift-order.new', {
                parent: 'gift-order',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift-order/gift-order-dialog.html',
                        controller: 'GiftOrderDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    totalPrice: null,
                                    shippingFee: null,
                                    shipmentDate: null,
                                    sender: null,
                                    recipient: null,
                                    greeting: null,
                                    phone: null,
                                    address: null,
                                    provinceNameUz: null,
                                    provinceNameRu: null,
                                    provinceNameEn: null,
                                    districtNameUz: null,
                                    districtNameRu: null,
                                    districtNameEn: null,
                                    langKey: null,
                                    eventType: null,
                                    status: null,
                                    provinceId: null,
                                    districtId: null,
                                    transactionId: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('gift-order', null, {reload: 'gift-order'});
                    }, function () {
                        $state.go('gift-order');
                    });
                }]
            })
            .state('gift-order.edit', {
                parent: 'gift-order',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift-order/gift-order-dialog.html',
                        controller: 'GiftOrderDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['GiftOrder', function (GiftOrder) {
                                return GiftOrder.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('gift-order', null, {reload: 'gift-order'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('gift-order.delete', {
                parent: 'gift-order',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift-order/gift-order-delete-dialog.html',
                        controller: 'GiftOrderDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['GiftOrder', function (GiftOrder) {
                                return GiftOrder.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('gift-order', null, {reload: 'gift-order'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            }).state('gift-order-detail.status', {
            parent: 'gift-order-detail',
            url: '/{status}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$translate', '$stateParams', '$state', '$uibModal', function ($translate, $stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gift-order/gift-order-status-dialog.html',
                    controller: 'GiftOrderStatusChangeDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GiftOrder', function (GiftOrder) {
                            return GiftOrder.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', {}, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }

})();
