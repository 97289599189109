(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window', '$filter', '$sce'];

    function DataUtils($window, $filter, $sce) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            getDeductedAmount: getDeductedAmount,
            getSentAmount: getSentAmount,
            getResponse: getResponse,
            renderToHtml: renderToHtml,
            getCurrencyNameForClient: getCurrencyNameForClient,
            getCurrencySymbolForClient: getCurrencySymbolForClient,
            getBankNameByIndex: getBankNameByIndex
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function getDeductedAmount(transaction) {
            var number = $filter('number');
            var currency = getCurrencyNameForClient(transaction.customerClient);
            if (transaction.localCurrency != null){
                currency = transaction.localCurrency;
            }
            return number(transaction.amount) + ' ' + currency;
        }

        function getCurrencyNameForClient(customerClient) {
            var currency = 'ruble';
            if (customerClient == 'paygram_global') {
                currency = 'dollar';
            }
            return currency;
        }

        function getSentAmount(transaction) {
            var number = $filter('number');
            if (transaction.serviceCurrency != null){
                return number(transaction.amountInSom) + " " + transaction.serviceCurrency;
            } else if (transaction.serviceType == 'SERVICE_PAYMENT') {
                return number(transaction.amountInSom) + " so'm";
            } else if (transaction.serviceType == 'SERVICE_RUS_PHONE') {
                return number(transaction.amountInSom) + (transaction.client == "010pay" ? " dollar" : " ruble");
            } else if (transaction.serviceType == 'SERVICE_KAZ_PHONE'
                || transaction.serviceType == 'SERVICE_KGZ_PHONE'
                || transaction.serviceType == 'SERVICE_TJK_PHONE'
                || transaction.serviceType == 'SERVICE_UKR_PHONE'
                || transaction.serviceType == 'SERVICE_ARM_PHONE'
                || transaction.serviceType == 'SERVICE_OK_CASH'
                || transaction.serviceType == 'SERVICE_EXPRESS_PAY'
                || transaction.serviceType == 'SERVICE_MOBILNIK') {
                return number(transaction.amountInSom) + " ruble";
            } else if (transaction.serviceType == 'SERVICE_PAYFORM_KAZ_TELE2'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_KCELL'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_ALTEL'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_ACTIV'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_MEGAFON'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_INDIGO'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_VAVILONMOBILE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_NURTELECOM'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_MEGACOM'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_MTC'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_MEGAFON'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_TELE2'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_MTC'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_KYEVSTAR'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_LIFE') {
                return number(transaction.amountInSom) + " dollar";
            } else if (transaction.serviceType == 'SERVICE_OK_CASH') {
                return number(transaction.amountInSom) + " rouble";
            } else {
                return number(transaction.amount + transaction.usedMileage) + " " + getCurrencyNameForClient(transaction.customerClient);
            }
        }

        function getResponse(transaction) {
            var response = "";

            if (transaction.autoModeResponse != null) {
                response = transaction.autoModeResponse;
            }
            if (transaction.yandexRequestPaymentResponse != null) {
                response += "Request payment response: " + transaction.yandexRequestPaymentResponse;
            }
            if (transaction.yandexProcessPaymentResponse != null) {
                response += "       Process payment response: " + transaction.yandexProcessPaymentResponse;
            }

            return response;
        }

        function renderToHtml(value) {
            return $sce.trustAsHtml(value);
        }

        function getCurrencySymbolForClient(client) {
            if (client == null || client == undefined) {
                return '';
            }
            if (client == 'bozoraka' || client == 'mobpay') {
                return 'fa fa-won';
            } else if (client == 'paygram') {
                return 'fa fa-rub'
            } else if (client == 'paygram_global') {
                return 'fa fa-dollar'
            }
        }

        function getBankNameByIndex(index) {
            var banks = [
                {id: '0', name: 'Bozoraka', code: '000'},
                {id: '1', name: '기업은행 (IBK)', code: '003'},
                {id: '2', name: '경남은행 (Kyongnam)', code: '039'},
                {id: '3', name: '우리은행 (Woori)', code: '020'},
                {id: '4', name: 'KEB하나은행 (KEBHana)', code: '081'},
                {id: '5', name: '신한은행 (Shinhan)', code: '088'},
                {id: '6', name: '국민은행 (Kookmin)', code: '004'},
                {id: '7', name: '농협은행 (NH)', code: '011'},
                {id: '8', name: '제일은행 (SC)', code: '023'}
            ];
            return banks[index].name;
        }
    }
})();
