(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('uniteller-payment', {
            parent: 'entity',
            url: '/uniteller-payment?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.unitellerPayment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/uniteller-payment/uniteller-payments.html',
                    controller: 'UnitellerPaymentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                field: null,
                client: null,
                valueString1: null,
                amount: null,
                fromDate: null,
                toDate: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        field: $stateParams.field,
                        client: $stateParams.client,
                        valueString1: $stateParams.valueString1,
                        amount: $stateParams.amount,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('unitellerPayment');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('uniteller-payment-detail', {
            parent: 'uniteller-payment',
            url: '/uniteller-payment/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.unitellerPayment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/uniteller-payment/uniteller-payment-detail.html',
                    controller: 'UnitellerPaymentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('unitellerPayment');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'UnitellerPayment', function($stateParams, UnitellerPayment) {
                    return UnitellerPayment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'uniteller-payment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('uniteller-payment-detail.edit', {
            parent: 'uniteller-payment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/uniteller-payment/uniteller-payment-dialog.html',
                    controller: 'UnitellerPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['UnitellerPayment', function(UnitellerPayment) {
                            return UnitellerPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('uniteller-payment.new', {
            parent: 'uniteller-payment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/uniteller-payment/uniteller-payment-dialog.html',
                    controller: 'UnitellerPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                paymentId: null,
                                amount: null,
                                phone: null,
                                email: null,
                                signature: null,
                                paymentType: null,
                                status: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('uniteller-payment', null, { reload: 'uniteller-payment' });
                }, function() {
                    $state.go('uniteller-payment');
                });
            }]
        })
        .state('uniteller-payment.edit', {
            parent: 'uniteller-payment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/uniteller-payment/uniteller-payment-dialog.html',
                    controller: 'UnitellerPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['UnitellerPayment', function(UnitellerPayment) {
                            return UnitellerPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('uniteller-payment', null, { reload: 'uniteller-payment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('uniteller-payment.delete', {
            parent: 'uniteller-payment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/uniteller-payment/uniteller-payment-delete-dialog.html',
                    controller: 'UnitellerPaymentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['UnitellerPayment', function(UnitellerPayment) {
                            return UnitellerPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('uniteller-payment', null, { reload: 'uniteller-payment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('uniteller-payment.profile', {
            url: '/profile/{phone}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'CUSTOMER_SUPPORT']
            },
            views: {
                'content@': {
                    controller: 'DepositProfileRedirectController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('uniteller-payment.transaction', {
            url: '/transaction/{transactionNo}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'CUSTOMER_SUPPORT']
            },
            views: {
                'content@': {
                    controller: 'DepositTransactionRedirectController',
                    controllerAs: 'vm'
                }
            }
        });
    }

})();
