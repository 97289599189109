(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('profile', {
                parent: 'entity',
                url: '/profile?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT'],
                    pageTitle: 'global.menu.user.information'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profiles.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    field: null,
                    fromDate: null,
                    toDate: null,
                    status: null,
                    client: null,
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            status: $stateParams.status,
                            client: $stateParams.client,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('profile-detail', {
                parent: 'entity',
                url: '/profile/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT'],
                    pageTitle: 'bozorakaAdminApp.profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-detail.html',
                        controller: 'ProfileDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-transaction', {
                parent: 'entity',
                url: '/profile/{id}/transactions?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT'],
                    pageTitle: 'bozorakaAdminApp.profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-transaction.html',
                        controller: 'ProfileTransactionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    pageTranHist: {
                        value: '1',
                        squash: true
                    },
                    sortTranHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pageBalHist: {
                        value: '1',
                        squash: true
                    },
                    sortBalHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pageBonHist: {
                        value: '1',
                        squash: true
                    },
                    sortBonHist: {
                        value: 'transaction.pubDate,desc',
                        squash: true
                    },
                    activeTab: 'tab_1'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageTranHist: PaginationUtil.parsePage($stateParams.pageTranHist),
                            sortTranHist: $stateParams.sortTranHist,
                            predicateTranHist: PaginationUtil.parsePredicate($stateParams.sortTranHist),
                            ascendingTranHist: PaginationUtil.parseAscending($stateParams.sortTranHist),
                            pageBalHist: PaginationUtil.parsePage($stateParams.pageBalHist),
                            sortBalHist: $stateParams.sortBalHist,
                            predicateBalHist: PaginationUtil.parsePredicate($stateParams.sortBalHist),
                            ascendingBalHist: PaginationUtil.parseAscending($stateParams.sortBalHist),
                            pageBonHist: PaginationUtil.parsePage($stateParams.pageBonHist),
                            sortBonHist: $stateParams.sortBonHist,
                            predicateBonHist: PaginationUtil.parsePredicate($stateParams.sortBonHist),
                            ascendingBonHist: PaginationUtil.parseAscending($stateParams.sortBonHist),
                            activeTab: $stateParams.activeTab
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }]
                    ,
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-transaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            // .state('profile-detail.edit', {
            //     parent: 'profile-detail',
            //     url: '/detail/edit',
            //     data: {
            //         authorities: ['ROLE_MANAGER']
            //     },
            //     onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
            //         $uibModal.open({
            //             templateUrl: 'app/entities/profile/profile-dialog.html',
            //             controller: 'ProfileDialogController',
            //             controllerAs: 'vm',
            //             backdrop: 'static',
            //             size: 'lg',
            //             resolve: {
            //                 entity: ['Profile', function (Profile) {
            //                     return Profile.get({id: $stateParams.id}).$promise;
            //                 }]
            //             }
            //         }).result.then(function () {
            //             $state.go('^', {}, {reload: false});
            //         }, function () {
            //             $state.go('^');
            //         });
            //     }]
            // })
            .state('profile.new', {
                parent: 'profile',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-dialog.html',
                        controller: 'ProfileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    balance: null,
                                    totalMileage: null,
                                    registrationDate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('profile');
                    });
                }]
            })
            .state('profile.edit', {
                parent: 'profile',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-dialog.html',
                        controller: 'ProfileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile.delete', {
                parent: 'profile',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-delete-dialog.html',
                        controller: 'ProfileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editBalance', {
                parent: 'profile-detail',
                url: '/balance/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-balance-dialog.html',
                        controller: 'ProfileBalanceDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editBonus', {
                parent: 'profile-detail',
                url: '/bonus/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-bonus-dialog.html',
                        controller: 'ProfileBonusDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editStatus', {
                parent: 'profile-detail',
                url: '/status/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-status-dialog.html',
                        controller: 'ProfileStatusDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.changePhone', {
                    parent: 'profile-detail',
                    url: '/phone-number/edit',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/profile/profile-change-phone-dialog.html',
                            controller: 'ProfileChangePhoneDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                entity: ['Profile', function (Profile) {
                                    return Profile.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('^', {}, {reload: false});
                        }, function () {
                            $state.go('^');
                        });
                    }]
                });
    }

})();
