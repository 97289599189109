(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TransactionController', TransactionController);

    TransactionController.$inject = ['$scope', '$state', 'Transaction', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'DateUtils', 'DataUtils', 'ProviderCountry', 'ProviderCategory', 'Provider', 'ProviderSegment'];

    function TransactionController($scope, $state, Transaction, ParseLinks, AlertService, paginationConstants, pagingParams, DateUtils, DataUtils, ProviderCountry, ProviderCategory, Provider, ProviderSegment) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.providerCountries = ProviderCountry.getAsList();
        vm.providerCategories = [];
        vm.providers = [];
        vm.providerSegments = [];
        vm.searchQuery = {
            field: pagingParams.field,
            amount: pagingParams.amount,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            gateway: pagingParams.gateway,
            status: pagingParams.status,
            mode: pagingParams.mode,
            providerCountryId: pagingParams.providerCountryId,
            providerCategoryId: pagingParams.providerCategoryId,
            providerId: pagingParams.providerId,
            providerSegmentId: pagingParams.providerSegmentId,
            client: pagingParams.client
        };
        vm.getProviderCategories = getProviderCategories;
        vm.getProviders = getProviders;
        vm.getProviderSegments = getProviderSegments;
        initDatePicker();

        if (!pagingParams.firstRun) {
            loadAll();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function getProviderCategories() {
            if (vm.searchQuery.providerCountryId == null || vm.searchQuery.providerCountryId == ""){
                vm.providerCategories = [];
                return;
            }

            ProviderCategory.getAllByCountry({
                id:vm.searchQuery.providerCountryId
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.providerCategories = data;
            }

            function onError() {
                vm.providerCategories = [];
            }
        }

        function getProviders() {
            if (vm.searchQuery.providerCategoryId == null || vm.searchQuery.providerCategoryId == "") {
                vm.providers = [];
                return;
            }
            Provider.getAllByCategory({
                id: vm.searchQuery.providerCategoryId
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.providers = data;
            }

            function onError() {
                vm.providers = [];
            }
        }

        function getProviderSegments() {
            if (vm.searchQuery.providerId == null || vm.searchQuery.providerId == "") {
                vm.providerSegments = [];
                return;
            }
            ProviderSegment.getAllByProvider({
                id: vm.searchQuery.providerId
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.providerSegments = data;
            }

            function onError() {
                vm.providerSegments = [];
            }
        }

        function loadAll() {
            Transaction.query({
                field: pagingParams.field,
                amount: pagingParams.amount,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                gateway: pagingParams.gateway,
                transactionStatus: pagingParams.status,
                transactionMode: pagingParams.mode,
                client: pagingParams.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                providerCountryId: pagingParams.providerCountryId,
                providerCategoryId: pagingParams.providerCategoryId,
                providerId: pagingParams.providerId,
                providerSegmentId: pagingParams.providerSegmentId,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.transactions = data;
                vm.page = pagingParams.page;
                vm.providers = pagingParams.providers;
                getProviderCategories();
                getProviders();
                getProviderSegments();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                amount: vm.searchQuery.amount,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                gateway: vm.searchQuery.gateway,
                status: vm.searchQuery.status,
                client: vm.searchQuery.client,
                providerCountryId: vm.searchQuery.providerCountryId,
                providerCategoryId: vm.searchQuery.providerCategoryId,
                providerId: vm.searchQuery.providerId,
                providerSegmentId: vm.searchQuery.providerSegmentId,
                mode: vm.searchQuery.mode,
                firstRun: false
            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'pubDate';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'pubDate';
            vm.reverse = false;
            vm.searchQuery = {
                field: null,
                amount: null,
                fromDate: null,
                toDate: null,
                gateway: null,
                status: 'UNKNOWN',
                mode: 'UNKNOWN'
            };
            vm.transition();
        }

        //function dateDiff(transaction) {
        //    return moment(transaction.approvalDate).diff(moment(transaction.pubDate));
        //}
    }
})();
