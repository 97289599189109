(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TarlanPaymentController', TarlanPaymentController);

    TarlanPaymentController.$inject = ['$state', 'DateUtils', 'DataUtils', 'TarlanPayment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function TarlanPaymentController($state, DateUtils, DataUtils, TarlanPayment, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = {
            userPhone: pagingParams.userPhone,
            sysPaymentId: pagingParams.sysPaymentId,
            account: pagingParams.account,
            status: pagingParams.status,
            transactionStatus: pagingParams.transactionStatus,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            from: pagingParams.from,
            to: pagingParams.to
        };

        loadAll();

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function loadAll () {
            TarlanPayment.query({
                userPhone: vm.searchQuery.userPhone,
                sysPaymentId: vm.searchQuery.sysPaymentId,
                account: vm.searchQuery.account,
                status: vm.searchQuery.status,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tarlanPayments = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                userPhone: vm.searchQuery.userPhone,
                sysPaymentId: vm.searchQuery.sysPaymentId,
                account: vm.searchQuery.account,
                status: vm.searchQuery.status,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to
            });
        }

        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                userPhone: null,
                sysPaymentId: null,
                account: null,
                status: null,
                transactionStatus: 'UNKNOWN',
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            };
            vm.transition();
        }
    }
})();
