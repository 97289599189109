/**
 * Created by Gayratjon Rayimjonov on 10/24/2017.
 */
(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsPeriodical', StatsPeriodical);

    StatsPeriodical.$inject = ['$resource', 'DateUtils'];

    function StatsPeriodical($resource, DateUtils) {
        var resourceUrl = 'api/stats-periodical';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }
})();
