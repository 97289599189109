(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('RobokassaPayment', RobokassaPayment)
        .factory('RobokassaPaymentTotalAmount', RobokassaPaymentTotalAmount);

    RobokassaPayment.$inject = ['$resource'];

    function RobokassaPayment ($resource) {
        var resourceUrl =  'api/robokassa-payments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    RobokassaPaymentTotalAmount.$inject = ['$resource'];

    function RobokassaPaymentTotalAmount ($resource) {
        var resourceUrl =  'api/robokassa-payments/total-amount';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }
})();
