(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('transaction', {
                parent: 'entity',
                url: '/transaction?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT'],
                    pageTitle: 'global.menu.transactions'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/transaction/transactions.html',
                        controller: 'TransactionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    field: null,
                    amount: null,
                    fromDate: null,
                    toDate: null,
                    gateway: null,
                    status: null,
                    mode: null,
                    providerCountryId: null,
                    providerCategoryId: null,
                    providerId: null,
                    providerSegmentId: null,
                    client: null,
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            amount: $stateParams.amount,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            gateway: $stateParams.gateway,
                            status: $stateParams.status,
                            mode: $stateParams.mode,
                            date: $stateParams.date,
                            providerCountryId: $stateParams.providerCountryId,
                            providerCategoryId: $stateParams.providerCategoryId,
                            providerId: $stateParams.providerId,
                            providerSegmentId:$stateParams.providerSegmentId,
                            client: $stateParams.client,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('gateway');
                        $translatePartialLoader.addPart('transactionMode');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('transaction-detail', {
                parent: 'entity',
                url: '/transaction/{id}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT'],
                    pageTitle: 'bozorakaAdminApp.transaction.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/transaction/transaction-detail.html',
                        controller: 'TransactionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('gateway');
                        $translatePartialLoader.addPart('transactionMode');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Transaction', function ($stateParams, Transaction) {
                        return Transaction.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'transaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('transaction-detail.edit', {
                parent: 'transaction-detail',
                url: '/{status}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_SELLER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/transaction/transaction-dialog.html',
                        controller: 'TransactionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Transaction', function (Transaction) {
                                return Transaction.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                            $state.go('^', {}, {reload: false});
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('transaction.new', {
                parent: 'transaction',
                url: '/new',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_SELLER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/transaction/transaction-dialog.html',
                        controller: 'TransactionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    description: null,
                                    amount: null,
                                    transactionType: null,
                                    pubDate: null,
                                    transactionNumber: null,
                                    errorMessage: null,
                                    status: null,
                                    requestNo: null,
                                    gateway: null,
                                    amountInSom: null,
                                    approvalDate: null,
                                    client: null,
                                    mode: null,
                                    autoModeResponse: null,
                                    mangoAutoModeResponse: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                            $state.go('transaction', null, {reload: 'transaction'});
                        }, function () {
                            $state.go('transaction');
                        });
                }]
            })
            .state('transaction.edit', {
                parent: 'transaction',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_SELLER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/transaction/transaction-dialog.html',
                        controller: 'TransactionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Transaction', function (Transaction) {
                                return Transaction.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                            $state.go('transaction', null, {reload: 'transaction'});
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('transaction.delete', {
                parent: 'transaction',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_SELLER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/transaction/transaction-delete-dialog.html',
                        controller: 'TransactionDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Transaction', function (Transaction) {
                                return Transaction.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                            $state.go('transaction', null, {reload: 'transaction'});
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('transaction-detail.editStatus', {
                parent: 'transaction-detail',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/transaction/transaction-status-dialog.html',
                        controller: 'TransactionStatusDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Transaction', function (Transaction) {
                                return Transaction.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('transaction-detail', null, {reload: 'transaction-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('stats-partner', {
                parent: 'entity',
                url: '/stats-partner?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'global.menu.reconciliation'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats-partner/stats-partner.html',
                        controller: 'StatsPartnerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    field: null,
                    fromDate: null,
                    toDate: null,
                    fromZonedDate: null,
                    toZonedDate: null,
                    status: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            fromZonedDate: $stateParams.fromZonedDate,
                            toZonedDate: $stateParams.toZonedDate,
                            status: $stateParams.status
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('gateway');
                        $translatePartialLoader.addPart('transactionMode');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
