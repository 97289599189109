(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsPartnerController', StatsPartnerController);

    StatsPartnerController.$inject = ['$http', '$scope', '$state', 'StatsPartner', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'DateUtils', 'DataUtils'];

    function StatsPartnerController($http, $scope, $state, StatsPartner, ParseLinks, AlertService, paginationConstants, pagingParams, DateUtils, DataUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.excel = excel;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            fromZonedDate: pagingParams.fromZonedDate,
            toZonedDate: pagingParams.toZonedDate,
            status: pagingParams.status
        };

        loadAll();

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function loadAll() {
            StatsPartner.query({
                field: pagingParams.field,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                fromZonedDate: pagingParams.fromZonedDate,
                toZonedDate: pagingParams.toZonedDate,
                transactionStatus: pagingParams.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.transactions = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromZonedDate: vm.searchQuery.fromZonedDate,
                toZonedDate: vm.searchQuery.toZonedDate,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                status: vm.searchQuery.status
            });
        }

        function search(searchQuery) {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'pubDate';
            vm.reverse = true;
            vm.transition();
        }

        function excel() {
            getDateTimeFromPicker();
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toDate);
            var data = "partner=" + vm.searchQuery.field + "&status=" + vm.searchQuery.status + "&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/stats-partner-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'statistics-by-partner-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });

        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'pubDate';
            vm.reverse = false;
            vm.searchQuery = {
                field: null,
                fromZonedDate: null,
                toZonedDate: null,
                fromDate: null,
                toDate: null,
                serviceType: 'UNKNOWN',
                status: 'UNKNOWN'
            };
            vm.transition();
        }
    }
})();
