(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('QiwiPaymentDeleteController',QiwiPaymentDeleteController);

    QiwiPaymentDeleteController.$inject = ['$uibModalInstance', 'entity', 'QiwiPayment'];

    function QiwiPaymentDeleteController($uibModalInstance, entity, QiwiPayment) {
        var vm = this;

        vm.qiwiPayment = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            QiwiPayment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
