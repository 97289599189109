(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('RobokassaPaymentController', RobokassaPaymentController);

    RobokassaPaymentController.$inject = ['$state', 'RobokassaPayment', 'RobokassaPaymentSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'RobokassaPaymentTotalAmount'];

    function RobokassaPaymentController($state, RobokassaPayment, RobokassaPaymentSearch, ParseLinks, AlertService, paginationConstants, pagingParams, RobokassaPaymentTotalAmount) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.paymentTypes = {
            '': "",
            Qiwi40PS: "QIWI Wallet",
            ElecsnetWalletRIBR: "Elecsnet Wallet",
            W1RIBR: "Wallet One",
            BankCardPSR: "Bank Card",
            MixplatMTSPSR: "MTS",
            MixplatBeelinePSR: "Beeline",
            MixplatTele2PSR: "Tele2",
            RapidaRIBEurosetR: "Euroset",
            RapidaRIBSvyaznoyR: "Svyaznoy"
        };
        vm.searchQuery = {
            valueString1: pagingParams.valueString1,
            valueInt1: pagingParams.valueInt1,
            amount: pagingParams.amount,
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };
        vm.getPaymentStatus = getPaymentStatus;

        loadAll();
        initDatePicker();
        getTotalAmount();

        function loadAll() {
            RobokassaPayment.query({
                valueString1: pagingParams.valueString1,
                valueInt1: pagingParams.valueInt1,
                amount: pagingParams.amount,
                field: pagingParams.field,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.robokassaPayments = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                valueString1: vm.searchQuery.valueString1,
                valueInt1: vm.searchQuery.valueInt1,
                amount: vm.searchQuery.amount,
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                valueInt1: 0,
                field: '',
                amount: null,
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function getPaymentStatus(signatureValue) {
            if (signatureValue == '' || signatureValue == null) {
                return "DROPPED";
            } else {
                return "SUCCESS";
            }
        }

        function getTotalAmount() {
            RobokassaPaymentTotalAmount.query({
                valueString1: vm.searchQuery.valueString1,
                field: vm.searchQuery.field,
                amount: vm.searchQuery.amount,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            }, function (data) {
                vm.totalAmount = data.totalAmount;
            });
        }
    }
})();
