(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ExchangeRateDeleteController',ExchangeRateDeleteController);

    ExchangeRateDeleteController.$inject = ['$uibModalInstance', 'entity', 'ExchangeRate'];

    function ExchangeRateDeleteController($uibModalInstance, entity, ExchangeRate) {
        var vm = this;

        vm.exchangerate = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ExchangeRate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
