(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('QiwiPayment', QiwiPayment)
        .factory('QiwiPaymentTotalAmount', QiwiPaymentTotalAmount);

    QiwiPayment.$inject = ['$resource', 'DateUtils'];

    function QiwiPayment ($resource, DateUtils) {
        var resourceUrl =  'api/qiwi-payments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lifetime = DateUtils.convertDateTimeFromServer(data.lifetime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    QiwiPaymentTotalAmount.$inject = ['$resource'];

    function QiwiPaymentTotalAmount ($resource) {
        var resourceUrl =  'api/qiwi-payments/total-amount';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }

})();
