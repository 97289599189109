(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SellerInvoiceController', SellerInvoiceController);

    SellerInvoiceController.$inject = ['$http', 'SellerUser', 'AlertService', 'DateUtils', 'SellerInvoice', '$translate'];

    function SellerInvoiceController($http, SellerUser, AlertService, DateUtils, SellerInvoice, $translate) {
        var vm = this;

        vm.clear = clear;
        vm.search = search;
        vm.excel = excel;
        vm.totalPrice = totalPrice;
        vm.searchQuery = {
            sellerId: null,
            fromDate: null,
            toDate: null,
            field: null
        };

        initDatePicker();
        getSellers();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function load() {
            SellerInvoice.query(vm.searchQuery, onSuccess, onError);

            function onSuccess(data) {
                vm.fullTotalPrice = 0;
                vm.sellerInvoice = data;
                console.log(vm.sellerInvoice)
                for (var i = 0; i <= vm.sellerInvoice.length; i++) {
                    var sellerInvoice = vm.sellerInvoice[i];
                    if (vm.sellerInvoice[i] != undefined ) {
                        vm.fullTotalPrice += sellerInvoice.productQuantity * sellerInvoice.productPrice;
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            getDateFromField();
            vm.searchQuery.field = $translate.use();
            load();
        }

        function clear() {
            vm.searchQuery = {
                sellerId: null,
                fromDate: null,
                toDate: null
            };
            initDatePicker();
        }

        function excel() {
            getDateFromField();

            vm.searchQuery.fromDate = DateUtils.converterDateFormat(vm.searchQuery.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateFormat(vm.searchQuery.toDate);

            $http({
                url: 'api/seller-invoice-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: vm.searchQuery, //this is your json data string
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'seller-invoice-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }

        function getSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.sellers = data;
            }
        }

        function getDateFromField() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function totalPrice(quantity, price) {
            return quantity * price;
        }

    }
})();
