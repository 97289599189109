(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('serviceSelection', serviceSelection);

    var services = {
        UNKNOWN: 'ALL',
        SERVICE_PAYMENT: 'Payment',
        SERVICE_GIFT: 'Gift'
    };

    function serviceSelection() {
        var template =
            '<select class="form-control" name="serviceType" ng-model="vm.searchQuery.serviceType">' +
                '<option value=""></option>' +
            '<option ng-repeat="(key, value) in ownVm.serviceMap" value="{{key}}">{{value}}</option>' +
            '</select>'

        var directive = {
            restrict: 'EA',
            template: template,
            controller: ServiceSelectionController,
            controllerAs: 'ownVm',
            bindToController: true
        };

        return directive;
    }

    ServiceSelectionController.$inject = ['Principal'];

    function ServiceSelectionController(Principal) {
        var ownVm = this;
        ownVm.serviceMap = {};
        if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_SUPPORT'])) {
            addServices([
                'UNKNOWN',
                'SERVICE_PAYMENT',
                'SERVICE_GIFT'
            ]);
        } else if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER'])) {
            // Remove or comment allServices, if you want to add ROLES manually
            var allServices = Object.keys(services);
            addServices(allServices);
        }

        function addServices(serviceKeys) {
            for (var i = 0; i < serviceKeys.length; i++) {
                var serviceKey = serviceKeys[i];
                ownVm.serviceMap[serviceKey] = services[serviceKey];
            }
        }
    }
})();
